import React, { createContext, useEffect, useState } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Main from '../component/main';
import Login from '../component/loginIn';
import { ThemeProvider } from 'react-bootstrap';
import { URL_DEFAULT } from '../axi';
import axios from 'axios';
import Loading from '../component/loading';

// Создаем контекст для аутентификации
const AuthContext = createContext();

export function AppRouste() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkSession = async () => {
            try {
                const response = await axios.post(URL_DEFAULT, { type: 'checkSession' });
                if (response.data.user) {
                    setIsAuthenticated(true);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        checkSession();
    }, []);

    if (loading) {
        return <Loading/>;
    }



    const router = createBrowserRouter([
        {
            path: '/',
            element: <ThemeProvider >{isAuthenticated ? <Main /> : <Login onLogin={() => setIsAuthenticated(true)} />}</ThemeProvider>,
        },
    ]);

    return (
        <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
            <RouterProvider router={router} />
        </AuthContext.Provider>
    );
}


