import { useState } from "react";
import {
    Button,
    Modal,
    Spinner,
    Toast,
} from "react-bootstrap";
import { SiteDelete } from "../../axi";

export default function DeleteCard({ setWiretapping, id }) {
    const [show, setShow] = useState(false);

    // отслеживаем состояния запроса
    const [formProgress, setFormProgress] = useState(false);
    const [Error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const deleteS = async (id) => {
        console.log(id);
        setFormProgress(true);

        let result = await SiteDelete(id);
        if (result === "Success") {
            // Выполните действия, если вход успешен
            setWiretapping();
            setFormProgress(false);
            setSuccess(true);
            handleClose();
        } else {
            // Выполните действия, если вход неуспешен
            setError(true);
        }
    };
    return (
        <>
            <Button variant="danger " onClick={handleShow}>
                Удалить
            </Button>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Вы уверены что хотите удалить
                        запись?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Чтобы её вернуть придётся заного её
                    создавать. Также, соответственно, она
                    перестанет обрабатываться Kron -
                    сообщения о состоянии сайта перестанет
                    приходить на почту.
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Отмена
                    </Button>
                    {formProgress ? (
                        <Spinner animation="border" />
                    ) : (
                        <Button
                            variant="primary"
                            onClick={() => deleteS(id)}
                        >
                            Да, уверен(а)
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
            <Toast
                className="d-inline-block m-1 masseg"
                bg={"danger"}
                show={Error}
                onClose={()=>setError(!Error)}
            >
                <Toast.Header>
                    <strong className="me-auto">
                        При удалении произошла ошибка
                    </strong>
                </Toast.Header>
                <Toast.Body>
                    Попробуйте снова или обротитесь в
                    потдержку
                </Toast.Body>
            </Toast>
            <Toast
                className="d-inline-block m-1 masseg"
                bg={"success"}
                show={success}
                onClose={()=>setSuccess(!success)}
            >
                <Toast.Header>
                    <strong
                        style={{ color: "rgb(107 141 55)" }}
                        className="me-auto"
                    >
                        Удаление прошло успешно
                    </strong>
                </Toast.Header>
                <Toast.Body >Удаление прошло успешно</Toast.Body>
            </Toast>
        </>
    );
}
