import { useEffect, useState } from "react";
import {
    Button,
    FloatingLabel,
    Form,
    InputGroup,
    OverlayTrigger,
    Spinner,
    Toast,
    Tooltip,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { SiteAdd, SiteUpdate } from "../../axi";

export default function FormAdd({
    show,
    onHide,
    provider,
    setWiretapping,
    element,
}) {
    const [formData, setFormData] = useState({
        id: "",
        url: "",
        name: "",
        zakazchik: "",
        contact: "",
        date: "",
        hosts: "",
        domen: "",
        summ: "",
        sslWarning: false,
    });

    useEffect(() => {
        if (!isEmpty(element)) {
            setFormData({
                id: element.id,
                url: element.url,
                name: element.name,
                zakazchik: element.zakazchik,
                contact: element.contact,
                date: element.date,
                hosts: element.provider_id.hosts_id,
                domen: element.provider_id.domen_id,
                summ: element.summ,
                sslWarning: element.sslWarning,
            });
        } else {
            setFormData({
                id: "",
                url: "",
                name: "",
                zakazchik: "",
                contact: "",
                date: "",
                hosts: "",
                domen: "",
                summ: "",
                sslWarning: false,
            });
        }
    }, [element]);

    // отслеживаем состояния запроса
    const [formProgress, setFormProgress] = useState(false);
    const [Error, setError] = useState(false);
    const [Success, setSuccess] = useState(false);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    // Обработчик отправки формы
    const handleSubmit = async (e) => {
        const form = e.currentTarget;
        setFormProgress(true);
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            setFormProgress(false);
        } else {
            e.preventDefault();
            let siteAddSubmit = "";
            if (isEmpty(element)) {
                siteAddSubmit = await SiteAdd(formData);
            } else {
                siteAddSubmit = await SiteUpdate(formData);
            }
            console.log(siteAddSubmit);
            console.log(formData);

            if (siteAddSubmit === "Success") {
                // Выполните действия, если вход успешен
                setFormProgress(false);
                setWiretapping();
                setSuccess(true);
                onHide();
            } else {
                // Выполните действия, если вход неуспешен
                setFormProgress(false);
                setError(true);
            }
        }
    };

    const isEmpty = (obj) => {
        return Object.keys(obj).length === 0;
    };

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Form
                    noValidate
                    onSubmit={handleSubmit}
                    onChange={handleChange}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {isEmpty(element)
                                ? "Добавление сайта для мониторинга"
                                : "Редактирование сайта для мониторинга"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {isEmpty(element) ? (
                            ""
                        ) : (
                            <input
                                value={formData.id}
                                onChange={handleChange}
                                name="id"
                                type="hidden"
                            />
                        )}
                        <InputGroup className="mb-3">
                            <InputGroup.Text
                                className="ms_groop"
                                id="basic-addon1"
                            >
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id="button-tooltip-2">
                                            Если имя на
                                            русском то
                                            обязательно
                                            нужно перевести
                                            в punycode
                                        </Tooltip>
                                    }
                                >
                                    <div
                                        id="ms1"
                                        className="warning flex"
                                    >
                                        !
                                    </div>
                                </OverlayTrigger>
                            </InputGroup.Text>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Ссылка (доменное имя сайта, без http|https) "
                                className="mb-3"
                            >
                                <Form.Control
                                    required
                                    type="text"
                                    name="url"
                                    value={formData.url}
                                    onChange={handleChange}
                                    placeholder="saite.ru"
                                />
                            </FloatingLabel>
                        </InputGroup>

                        <FloatingLabel
                            controlId="floatingInput"
                            label="Имя сайта"
                            className="mb-3"
                        >
                            <Form.Control
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                type="text"
                                placeholder="saite.ru"
                            />
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Заказчик"
                            className="mb-3"
                        >
                            <Form.Control
                                name="zakazchik"
                                value={formData.zakazchik}
                                onChange={handleChange}
                                type="text"
                                placeholder="saite.ru"
                            />
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Контактная информация (email | tel)"
                            className="mb-3"
                        >
                            <Form.Control
                                name="contact"
                                value={formData.contact}
                                onChange={handleChange}
                                type="text"
                                placeholder="saite.ru"
                            />
                        </FloatingLabel>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Дата выгрузки в сеть"
                            className="mb-3"
                        >
                            <Form.Control
                                type="date"
                                value={formData.date}
                                onChange={handleChange}
                                name="date"
                                className="custom-date-input"
                            />
                        </FloatingLabel>

                        <Form.Check
                            className="mb-3"
                            name="sslWarning"
                            checked={formData.sslWarning}
                            onChange={handleChange}
                            label={`Сертификат обычного вида? Если нет уберите галочку`}
                        />

                        <Form.Select
                            className="mb-3"
                            name="hosts"
                            value={formData.hosts}
                            onChange={handleChange}
                        >
                            <option>
                                --Выберите хостинг--
                            </option>
                            {provider.map((e) => (
                                <option key={e.id} value={e.id}>
                                    {e.name}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Select
                            className="mb-3"
                            name="domen"
                            value={formData.domen}
                            onChange={handleChange}
                        >
                            <option>
                                --Выберите провайдер--
                            </option>
                            {provider.map((e) => (
                                <option key={e.id} value={e.id}>
                                    {e.name}
                                </option>
                            ))}
                        </Form.Select>
                        <FloatingLabel
                            controlId="floatingInput"
                            label="Сумма продления"
                            className="mb-3"
                        >
                            <Form.Control
                                name="summ"
                                value={formData.summ}
                                onChange={handleChange}
                                type="text"
                                placeholder="saite.ru"
                            />
                        </FloatingLabel>
                    </Modal.Body>
                    <Modal.Footer>
                        {formProgress ? (
                            <Spinner animation="border" />
                        ) : (
                            <Button
                                className="bek"
                                variant="primary"
                                type="submit"
                            >
                                Сохранить
                            </Button>
                        )}
                        <Button onClick={onHide}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <Toast
                className="d-inline-block m-1 masseg"
                bg={"danger"}
                show={Error}
                onClose={() => setError(!Error)}
            >
                <Toast.Header>
                    <strong className="me-auto">
                        {isEmpty(element)
                            ? "При создании произошла ошибка"
                            : "При редактировании произошла ошибка"}
                    </strong>
                </Toast.Header>
                <Toast.Body>
                    Попробуйте снова или обратитесь в поддержку
                </Toast.Body>
            </Toast>
            <Toast
                className="d-inline-block m-1 masseg"
                bg={"success"}
                show={Success}
                onClose={() => setSuccess(!Success)}
            >
                <Toast.Header>
                    <strong
                        style={{ color: "#fff" }}
                        className="me-auto"
                    >
                        {isEmpty(element)
                            ? "Создание прошло успешно"
                            : "Изменение прошло успешно"}
                    </strong>
                </Toast.Header>
                <Toast.Body>
                    {isEmpty(element)
                        ? "Создание прошло успешно"
                        : "Изменение прошло успешно"}
                </Toast.Body>
            </Toast>
        </>
    );
}
