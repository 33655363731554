import { useEffect, useState } from "react";
import { Alert, Button, Card, Nav, Spinner } from "react-bootstrap";
import { SiteInfo } from "../../axi";
import DeleteCard from "../delete";

export default function SiteCard({ e, provider=null,hosts=null, stack=null, setWiretapping, taskRedact }) {
    const [activeTab, setActiveTab] = useState("#first");
    const [sost, setSost] = useState(null);

    const handleSost = async (url)=>{
        const rezult = await SiteInfo(url);
        console.log(rezult);
        
        if (rezult.newtworkOk) {
            setSost(rezult);
        }
    }
    const handleTabSelect = (eventKey) => {
        setActiveTab(eventKey);
    };

    useEffect(() => {}, [activeTab]);
    
    return (
        <Card className="site_card" >
            <Card.Header>
                <Nav
                    variant="tabs"
                    defaultActiveKey="#first"
                    onSelect={handleTabSelect}
                >
                    <Nav.Item>
                        <Nav.Link href="#first">
                            Информация
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#second" onClick={()=>handleSost(e.url)}>
                            Состояние
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#management">
                            Управление
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
            </Card.Header>
            <Card.Body>
                <Card.Title>{e.name}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                    <Card.Link
                        target="_blank"
                        href={
                            e.url.startsWith("http://") ||
                            e.url.startsWith("https://")
                                ? e.url
                                : `http://${e.url}`
                        }
                        rel="noopener noreferrer"
                    >
                        {e.url}
                    </Card.Link>
                </Card.Subtitle>
                {activeTab === "#first" && (
                    <div>
                        <h5 className="flex" style={{justifyContent:'flex-start'}}>Информация </h5>
                        <ul>
                            {e.zakazchik && <li>Заказчик: {e.zakazchik}</li>}
                            {e.contact && <li>Контактная информаця: {e.contact}</li>}
                            {provider!==null && provider!==false && <li>Домен: {provider.name}</li>}
                            {hosts!==null && hosts!==false && <li>Хостинг: {hosts.name}</li>}
                            {stack!==null && stack!==false && <li>Исп. языки/технологии: {stack.map((s)=>(stack.length ===1 || s===stack[stack.length-1] ? s.name :s.name+" | "))}</li>}
                            {e.summ && <li>Сумма продления: {e.summ} ₽</li>}


                        </ul>
                    </div>
                )}
                {activeTab === "#second" && (
                    <div>
                        <h5>Состояние</h5>
                        {sost !== null ?
                         <ul className="status">
                         {sost.newtworkOk && <li><Alert variant={sost.newtworkOk.status ? 'success':'danger'}>{sost.newtworkOk.message}</Alert> </li>}
                         {sost.robots && <li><Alert variant={sost.robots.status ? 'success':'warning'}>{sost.robots.message}</Alert> </li>}
                         {sost.sitemapOk && <li><Alert variant={sost.sitemapOk.status ? 'success':'warning'}>{sost.sitemapOk.message}</Alert> </li>}
                         {sost.sslOk && <li><Alert variant={sost.sslOk.status ? 'success':'danger'}>{sost.sslOk.message}</Alert> </li>}
                         {sost.timeSSL && <li><Alert variant={sost.timeSSL.status ? 'success':'warning'}>Поставлен: { sost.timeSSL.message.date_start}<br/>Истекает: { sost.timeSSL.message.date_end}</Alert> </li>}
                       


                     </ul>:
                        <Spinner animation="border" />
                        }
                        
                    </div>
                )}
                {activeTab === "#management" && (
                    <div>
                        <h5>Управление записью</h5>
                        <div className="flex site_card_butBox mt-3">
                        <DeleteCard setWiretapping={setWiretapping} id={e.id}/>
                        <Button onClick={()=>taskRedact(e)}>
                            Редактировать
                        </Button>
                        </div>
                    </div>
                )}
            </Card.Body>
        </Card>
    );
}
