import { Button, Container } from "react-bootstrap";


export default function Header(params) {
    
    return(
        <header className="header">
        <Container className="header_container flex">
            <img className="header_logo" src="/logo_lite.png" alt="" />
            <Button className="bek">
                Выйти
            </Button>
        </Container>
    </header>
    )
}