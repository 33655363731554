import "./loginin.css";
import {
    Button,
    Container,
    Form,
    InputGroup,
    Modal,
    Toast,
} from "react-bootstrap";
import { LoginIn } from "../../axi";
import { useState } from "react";
export default function Login({ onLogin }) {
    const [formData, setFormData] = useState({
        login: "",
        password: "",
    });
    const [showA, setShowA] = useState(false);
    const toggleShowA = () => setShowA(!showA);
    const [validated, setValidated] = useState(false);

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    // Обработчик отправки формы
    const handleSubmit = async (e) => {
        const form = e.currentTarget;

        if (form.checkValidity() === false) {
          e.preventDefault();
          e.stopPropagation();

          setValidated(true);
        }else{
            e.preventDefault();

            const isLoggedIn = await LoginIn(formData);
            
            if (isLoggedIn) {
                // Выполните действия, если вход успешен
                onLogin();
                setValidated(!validated)
            } else {
                // Выполните действия, если вход неуспешен
                setShowA(true)
            }
        }
      
    };
    return (
        <Container className="login flex">
            <Modal.Dialog size="lg" className="login_box">
                <Form
                    noValidate 
                    validated={validated}
                    onSubmit={handleSubmit}
                    onChange={handleChange}
                >
                    <Modal.Header className="mb-3">
                        <Modal.Title>
                            Войдите в систему
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <InputGroup className="mb-4">
                            <InputGroup.Text
                                className="label_log"
                                id="basic-addon1"
                            >
                                Логин
                            </InputGroup.Text>
                            <Form.Control
                                name="login"
                                className="login_inp"
                                required
                                placeholder="Логин"
                                aria-label="login"
                                aria-describedby="basic-addon1"
                            />
                        </InputGroup>
                        <InputGroup className="mb-4">
                            <InputGroup.Text
                                className="label_log"
                                id="basic-addon1"
                            >
                                Пароль
                            </InputGroup.Text>
                            <Form.Control
                                className="login_inp"

                                name="password"
                                type="password"
                                required
                                placeholder="Пароль"
                                aria-label="password"
                                aria-describedby="basic-addon1"
                            />
                        </InputGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className="bek"
                            variant="primary"
                            type="submit"
                        >
                            Войти
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Dialog>
            <Toast className="d-inline-block m-1 masseg" bg={"danger"} show={showA} onClose={toggleShowA}>
                <Toast.Header>
                    <strong className="me-auto">
                        Неверный логин или пароль
                    </strong>
                </Toast.Header>
                <Toast.Body >Попробуйте снова</Toast.Body>
            </Toast>
          
        </Container>
    );
}
