import { Container } from "react-bootstrap"
import "./loading.css"

export default function Loading(params) {
    
    return (
        <>
            <Container className="flex">
                Loading
            </Container>
        </>
    )
}